.picturechoice__options {
	& {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		align-items: stretch !important;
	}
	&.valigned {
		display: inline-flex;
		flex-direction: column;
		max-width: 100%;
		align-items: stretch;
		width: auto;
	}
	&:not(.valigned) {
		.pictureChoice__optionWrapper {
			max-width: 215px;
			@media (max-width: 600px) {
				max-width: 480px;
			}
		}
	}
	> div {
		&.clicked {
			animation-name: qf-picture-choice-vibrate;
			animation-duration: 0.4s;
			animation-timing-function: linear;
			animation-fill-mode: forwards;
		}
	}

	.pictureChoice__optionLabelWrapper {
		& {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex: 1 1 0%;
			appearance: none;
			text-align: left;
		}

		&:hover .pictureChoice__optionKey .pictureChoice__optionKeyTip {
			visibility: visible !important;
			opacity: 1 !important;
			transform: none !important;
		}

		.pictureChoice__optionLabel {
			flex-grow: 1;
			padding-right: 12px;
			overflow-wrap: break-word;
			max-width: calc(100% - 27px);
		}

		.pictureChoice__optionKey {
			& {
				position: relative;
				width: 27px;
				height: 27px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				border-width: 1px;
				border-style: solid;
				font-size: 14px;
			}

			.pictureChoice__optionKeyTip {
				position: absolute;
				top: -25px;
				font-size: 10px;
				font-weight: bold;
				text-transform: uppercase;
				padding: 2px 3px;
				border-radius: 2px;
				transition: 0.2s all ease-in-out;
				transform: translateY(5px);
				visibility: hidden;
				opacity: 0;
			}
		}
	}
}

@keyframes qf-picture-choice-vibrate {
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(0.95);
	}

	50% {
		transform: scale(0.98);
	}

	75% {
		transform: scale(0.95);
	}

	100% {
		transform: scale(1);
	}
}
